div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

div[class*="acWidgetContainer"] {
    right: 50%;
    bottom: min(10vh, 10vw);
    width: 50vw;
    height: 40vh;
}

div[class*="acFrameContainer"].show {
    right: min(20vh, 20vw);
    width: 50vw;
    display: flex;
    height: 60vh;
    margin-left: 50%;
    margin-top: -45vh;
}

button[class*="acButtonStyles"] {
    width: 70px;
    height: 70px;
    position: absolute;
    margin-left: 100%;
}

.foo { flex: 1; }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}
